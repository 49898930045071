<div class="container-md p-3 p-sm-5 shadow-lg rounded">
  <div class="row">
    <div id="Title" class="col align-center" [innerHtml]="_returnHtmlFromRichText(title)"></div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="4000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/5018655.jpg" class="d-block w-100 h-100"
              alt="Gruppenbild (von oben aufgenommen) der Teilnehmenden des Bürgerrates „Ernährung im Wandel“ mit Bundestagspräsidentin Bärbel Bas in der Mitte." />
          </div>
          <div class="carousel-item">
            <img src="assets/frau-in-runde-stehend.jpg" class="d-block w-100 h-100"
              alt="Bild von einer Frau, die in einer Gesprächsrunde steht" style="object-position: 50% 40%" />
            <div class="carousel-caption d-none d-md-block p-1">
              <a href="https://www.flickr.com/photos/mehr-demokratie/48738362241/in/album-72157710855822351/"
                target="_blank">Foto</a>
              von
              <a href="https://www.mehr-demokratie.de/" target="_blank">Mehr Demokratie</a>
              lizensiert durch
              <a href="https://creativecommons.org/licenses/by-sa/2.0/deed.de" target="_blank">CC BY-SA 2.0</a>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/demo-klimaschutz-jetzt.jpg" class="d-block w-100 h-100" alt="Schild Klimaschutz Jetzt"
              style="object-position: 50% 45%" />
          </div>
          <div class="carousel-item">
            <img src="assets/großer-saal.jpg" class="d-block w-100 h-100"
              alt="Bild von einer Menschenmenge in einem großen Saal" style="object-position: 50% 40%" />
            <div class="carousel-caption d-none d-md-block p-1">
              <a href="https://www.flickr.com/photos/mehr-demokratie/48825755271/in/album-72157710855822351/"
                target="_blank">Foto</a>
              von
              <a href="https://www.mehr-demokratie.de/" target="_blank">Mehr Demokratie</a>
              lizensiert durch
              <a href="https://creativecommons.org/licenses/by-sa/2.0/deed.de" target="_blank">CC BY-SA 2.0</a>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/demo-noch-kurz-die-welt-retten.jpg" class="d-block w-100 h-100"
              alt="Demonstrationsschild nur noch kurz die Welt retten" style="object-position: 50% 35%" />
          </div>
          <div class="carousel-item">
            <img src="assets/ältere-dame-in-plenum-aufzeigend.jpg" class="d-block w-100 h-100"
              alt="Bild einer älteren Dame, die in einem Plenum aufzeigt" style="object-position: 50% 45%" />
            <div class="carousel-caption d-none d-md-block p-1">
              <a href="https://www.flickr.com/photos/mehr-demokratie/48825381953/in/album-72157710855822351/"
                target="_blank">Foto</a>
              von
              <a href="https://www.mehr-demokratie.de/" target="_blank">Mehr Demokratie</a>
              lizensiert durch
              <a href="https://creativecommons.org/licenses/by-sa/2.0/deed.de" target="_blank">CC BY-SA 2.0</a>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/demo-if-climate-were-a-bank.jpg" class="d-block w-100 h-100"
              alt="Demonstrationsschild if climate were a bank" style="object-position: 50% 45%" />
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <!-- Progressbar -->
  <div class="row mt-3">
    <!-- Desktop  -->
    <div class="col-12 d-none d-md-block">
      <div class="progress">
        <div [ngClass]="
            numberOfActivePhase == 1
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " role="progressbar" [ngStyle]="{
            'font-weight': numberOfActivePhase == 1 ? 'bold' : 'normal',
            width: '32%'
          }" aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
          {{ phases[0] }}
        </div>
        <div class="progress-bar bg-light" role="progressbar" style="width: 3%" aria-valuenow="3" aria-valuemin="0"
          aria-valuemax="100">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </div>
        <div [ngClass]="
            numberOfActivePhase == 2
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " [ngStyle]="{
            'font-weight': numberOfActivePhase == 2 ? 'bold' : 'normal',
            width: '32%'
          }" role="progressbar" aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
          {{ phases[1] }}
        </div>
        <div class="progress-bar bg-light" role="progressbar" style="width: 3%" aria-valuenow="3" aria-valuemin="0"
          aria-valuemax="100">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </div>
        <div [ngClass]="
            numberOfActivePhase == 3
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " role="progressbar" [ngStyle]="{
            'font-weight': numberOfActivePhase == 3 ? 'bold' : 'normal',
            width: '31%'
          }" aria-valuenow="31" aria-valuemin="0" aria-valuemax="100">
          {{ phases[2] }}
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="col-12 d-block d-md-none">
      <div class="progress">
        <div [ngClass]="
            numberOfActivePhase == 1
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " role="progressbar" [ngStyle]="{
            'font-weight': numberOfActivePhase == 1 ? 'bold' : 'normal',
            width: '100%'
          }" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
          {{ phases[0] }}
        </div>
      </div>

      <div class="progress">
        <div class="progress-bar bg-light" role="progressbar" style="width: 4%" aria-valuenow="4" aria-valuemin="0"
          aria-valuemax="100">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </div>
        <div [ngClass]="
            numberOfActivePhase == 2
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " role="progressbar" [ngStyle]="{
            'font-weight': numberOfActivePhase == 2 ? 'bold' : 'normal',
            width: '96%'
          }" aria-valuenow="96" aria-valuemin="0" aria-valuemax="100">
          {{ phases[1] }}
        </div>
      </div>
      <div class="progress">
        <div class="progress-bar bg-light" role="progressbar" style="width: 4%" aria-valuenow="4" aria-valuemin="0"
          aria-valuemax="100">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </div>
        <div [ngClass]="
            numberOfActivePhase == 3
              ? 'progress-bar bg-success'
              : 'progress-bar bg-light'
          " role="progressbar" [ngStyle]="{
            'font-weight': numberOfActivePhase == 2 ? 'bold' : 'normal',
            width: '96%'
          }" aria-valuenow="96" aria-valuemin="0" aria-valuemax="100">
          {{ phases[2] }}
        </div>
      </div>
    </div>
  </div>
  <div class="row  mt-3">
    <div class="col-auto">
      <app-sign-letter-modal></app-sign-letter-modal>
    </div>
  </div>
  <!-- <div class="col-auto mt-3">
    <app-contact-modal></app-contact-modal>
  </div> -->
  <div class="row mt-3" *ngIf="notice">
    <div class="col alert alert-info" [innerHtml]="_returnHtmlFromRichText(notice)"></div>
  </div>
</div>