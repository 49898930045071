<footer class="footer container-md mt-auto p-3 px-sm-5">
  <p>
    <a routerLink="/" routerLinkActive="active">Offener Brief</a> -
    <a routerLink="/impressum" routerLinkActive="active">Impressum</a> -
    <a routerLink="/datenschutz" routerLinkActive="active">Datenschutzerklärung</a> - März 2024
  </p>
  <p>
    <small>{{ bottomNotice }} </small>
  </p>
</footer>
